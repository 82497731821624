﻿// Obsah nad patičkou


// obalí container černou barvou
.gradient-wrapper {
#gradient.vertical(#e6e6e6;#fff;0%;50%);
	padding-top: 0px;
	padding-bottom: 0px;
	margin-top: 60px;
}

.homepage {
.gradient-wrapper {
	background-image: none;
	margin-top: 0px;
}
}

.advantage {
  .make-sm-column(3);
  text-align: center;
	
	background-repeat: no-repeat;
	background-position: center 0px;
	padding-top: 84px;
	&.ico-prase {
		background-image: url("../Content/img/ico-circle-prase.png");
	}
	&.ico-mesec {
		background-image: url("../Content/img/ico-circle-mesec.png");
	}
	&.ico-ruce {
		background-image: url("../Content/img/ico-circle-ruce.png");
	}
	&.ico-graf {
		background-image: url("../Content/img/ico-circle-graf.png");
	}
	&.ico-lupa {
		background-image: url("../Content/img/ico-circle-lupa.png");
	}
	p {
	 line-height: 24px;
	}
	
}

.advantages-wrapper {
				
				.advantage {
					.make-sm-column(3);
					
				}

}


// Patička
.footer, .footer-contact {
  .container();
	.box{
		.make-sm-column(7);
		&:nth-child(2) {
			.make-sm-column(8);
		}
		p {
			margin-top: 0;
			font-size: 16px;
			}
		h4 {
			margin-bottom: 6px;
			font-size: 18px;
			}
	
	}
  
 
}

.footer-contact {
  p {
    margin-top: 30px;
  }
}

.footer {
padding-top: 46px;
	padding-bottom: 30px;
	margin-bottom: 0;
}



// obalí container černou barvou
.black-wrapper {
  background-color: #000;
  color: #FFF;
	padding-top: 30px;
	padding-bottom: 30px;
}

// odkaz na wense
.signature {
	width: 173px;
	height: 15px;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 100%;
	text-indent: -9999px;
	background-image: url("../Content/img/author_wense-interactive-design_black.png");
	.center-block();
	.text-center();
	float: right;	
	opacity: .8;
	transition: .1s;
	&:hover {
		opacity: 1.0; 
	}
}