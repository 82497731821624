﻿.timeline {
    list-style: none;
    padding: 0px 0 60px; 
    position: relative;
    float: left;
    margin-top: 40px;
    width: 100%;
    
      h4 {
          text-transform: uppercase;
          font-size: 20px;
          margin-bottom: 4px;
      }
      .sipka {
background-image: url("../Content/img/sipka.png"); 
    background-position: -1px top;
    background-repeat: no-repeat;
    width: 32px;
    height: 80px;
    margin: 0 auto;
    text-align: center;

      }
}

.timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #000;
    left: 50%;
    margin-left: -1.5px;
    
}

.timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li:before,
.timeline > li:after {
    content: " ";
    display: table;
}

.timeline > li:after {
    clear: both;
}

.timeline > li > .timeline-panel {
    
    @media (max-width: @screen-xs-max) { width: 47%; }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { width: 48%; }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { width: 49%; }
    @media (min-width: @screen-lg-min) { width: 49%; }
    float: left;
    border-top: 2px solid #000;
    border-radius: 2px;
    padding: 20px;
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
}



.timeline > li > .timeline-panel:before {
    display: none;
}

.timeline > li > .timeline-panel:after {
    display: none;
}

.timeline > li > .timeline-badge {

    display: none;
}

.timeline > li.timeline-inverted > .timeline-panel {
    float: right;

}

.timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.timeline-badge.primary {
    background-color: #2e6da4 !important;
}

.timeline-badge.success {
    background-color: #3f903f !important;
}

.timeline-badge.warning {
    background-color: #f0ad4e !important;
}

.timeline-badge.danger {
    background-color: #d9534f !important;
}

.timeline-badge.info {
    background-color: #5bc0de !important;
}

.timeline-title {
    margin-top: 0;
    color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
    margin-bottom: 0;
}

.timeline-body > p + p {
    margin-top: 5px;
}

li.timeline-inverted > .timeline-panel {
    @media (max-width: @screen-xs-max) { width: 52%; }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { width: 275px; }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { width: 357px; }
    @media (min-width: @screen-lg-min) { width: 51%; }
}
