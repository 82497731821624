﻿.aim {
    position: relative;
    overflow: hidden;
    margin: 0;
    clear: both;
    padding: 0;

    .btn-default {
        @media (max-width: @screen-xs-max) { display: none; }
        margin-top: 10px;
        border: none;
        text-transform: none;
        .border-right-radius(0);
        .border-left-radius(0);
        background-color: #fff;
        color: #000;
        text-transform: uppercase;
        border: 1px solid #fff;
        padding: 4px 40px;
        font-size: 16px;

        &:hover,
        &:focus {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
            .box-shadow(none);
        }
    }

    a {
      background-position: center center;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
      position: relative;
      float: left;
      text-decoration: none;
      padding-top: 9%;

      &:hover, &:visited {
        text-decoration: none;
      }

      > div {
        width: 100%;
        height: 100%;
        display: table;
        color: white;
          
        div.accordion-inner {
            display: table-cell;
            vertical-align: middle;
            text-align: center;

            p {
                width: 50%;
                margin-left: auto;
                margin-right: auto; 
                font-size: 16px;
                @media (max-width: @screen-xs-max) { display: none; }
            }
            h2 {
                @media (max-width: @screen-xs-max) { display: none; }
                @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { font-size: 22px; }
            }
        }
      }

      div.accordion-inner .inner-content {
              opacity: 0;
              display: block;
              position: absolute;
              left: 0;
              right: 0;
              margin: 0 auto;

      }

      &.active > div div.accordion-inner .inner-content.active-inner {
              
              -moz-transition: opacity 0.5s;
              -o-transition: opacity 0.5s;
              -webkit-transition: opacity 0.5s;
              transition: opacity 0.5s;              

              opacity: 1;

              @media (max-width: @screen-xs-max) { display: none; }
      }

        

    }
    a.active > div div.accordion-inner h2 {
        font-size: 44px;
        @media (max-width: @screen-xs-max) { display: block; font-size: 30px;}
    }
}

div.accordion-wrapper {
  height: 810px;

  div.hp-accordeon {
    visibility: hidden;
    opacity: 0;
    
    &.active {
      
      visibility: visible;
      opacity: 1;

      -moz-transition: opacity 1s;
      -o-transition: opacity 1s;
      -webkit-transition: opacity 1s;
      transition: opacity 1s;
    }
  }
}