﻿@import (reference) "../Content/bootstrap/less/mixins/border-radius.less";
@import (reference) "../Content/bootstrap/less/variables.less";

.modal-member {

  .modal-header {
    border-bottom: 0;
  }

  .modal-content {
    cursor: default;
    border-radius: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    h2 {
      color: #000;
      margin-bottom: 0px;
    }

    p.funkce {
      font-family: "Proxima N W02 Bold", Arial, sans-serif;
      font-size: 19px;
    }

    img {
      .border-top-radius(840px);
      .border-bottom-radius(840px);
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
