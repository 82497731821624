﻿@import (reference) "../Content/bootstrap/less/mixins/grid.less";
@import (reference) "../Content/bootstrap/less/variables.less";
@import (reference) "_variables.less";


.chart {
    margin-top: 55px;
    .make-row(0);

    .chart-col {
        .make-xs-column(2,0);
        min-height: 300px;
    }

    .char-x-value {
        .make-xs-column(2,0);
        text-align: center;
        padding-top: 6px;

        &.val-1 {
            .make-xs-column-offset(4);
        }

        &.val-2 {
            .make-xs-column-offset(2);
        }

        &.val-3 {
            .make-xs-column-offset(2);
        }
    }

    .ax-y-values {
        border-right: 1px solid black;
        position: relative;

        .ax-y-value {
            position: absolute;
        }
    }

    .empty-col, .data-col {
        border-bottom: 1px solid black;
    }

    .data-col {
        background-image: url("../Content/img/sloupec-grafu.jpg");
        background-position: bottom;

        &.data-col-prediction {
            background-image: url("../Content/img/sloupec-grafu-bw.jpg");
        }
    }

    .area, .space, .val {
        float: left;
        width: 100%;
    }

    .area {
        height: 0;
        //background-image: url("../Content/img/sloupec-grafu.jpg");
        //background-position: bottom;
        background-color: transparent;
        -moz-transition: height 2s;
        -webkit-transition: height 2s;
        -o-transition: height 2s;
        transition: height 2s;
    }

    .space {
        height: 80%;
        background-color: #FFF;
    }

    .val {
        background-color: #FFF;
        height: 20%;
        text-align: center;
        padding-top: 30px;
        font-size: 15px;

        > span {
            visibility: hidden;
            -moz-transition: visibility 1s;
            -webkit-transition: visibility 1s;
            -o-transition: visibility 1s;
            transition: visibility 1s;
        }
    }

    .data-col-prediction .val span {
        //visibility: hidden;
    }
}



.chart .val {
    @media (max-width: @screen-xs-max) {
        font-size: 13px;
    }
}

/**/

.swiper-container {

    .swiper-button-next {
        background-image: none;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-left: 13px solid #000;
        margin-right: -10px;

          @media (max-width: @screen-xs-max) {
        border-width: 15px;
    }
    }

    .swiper-button-prev {
        background-image: none;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent;
        border-right: 13px solid #000;
        margin-left: -10px;
               @media (max-width: @screen-xs-max) {
        border-width: 15px;
    }
    }



    .chart {
        margin-top: 0;
        .make-row(0);

       
            .y-val {
                padding-top: 6px;
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                border-top: 1px solid #000;
                width: 100%;
                height: 45px;
                font-size: 15px;
            }
     


        .chart-col {
            .make-xs-column(10);
            padding: 0;
            min-height: 255px;
        }

        .char-x-value {
            .make-xs-column(2,0);
            text-align: center;
            padding-top: 6px;

            &.val-1 {
                .make-xs-column-offset(4);
            }

            &.val-2 {
                .make-xs-column-offset(2);
            }

            &.val-3 {
                .make-xs-column-offset(2);
            }
        }

        .ax-y-values {
            border-right: 1px solid black;
            position: relative;

            .ax-y-value {
                position: absolute;
            }
        }

        .empty-col, .data-col {
            border-bottom: 1px solid black;
        }

        .data-col {
            background-image: url("../Content/img/sloupec-grafu.jpg");
            background-position: bottom;

            &.data-col-prediction {
                background-image: url("../Content/img/sloupec-grafu-bw.jpg");
            }
        }

        .area, .space, .val {
            float: left;
            width: 100%;
        }

        .area {
            height: 0;
            //background-image: url("../Content/img/sloupec-grafu.jpg");
            //background-position: bottom;
            background-color: transparent;
            -moz-transition: height 2s; /* Firefox 4 */
            -webkit-transition: height 2s; /* Safari and Chrome */
            -o-transition: height 2s;
            transition: height 2s; /* Opera */
        }

        .space {
            height: 80%;
            background-color: #FFF;
        }

        .val {
            background-color: #FFF;
            height: 20%;
            text-align: center;
            padding-top: 30px;

            > span {
                visibility: hidden;
                -moz-transition: visibility 1s; /* Firefox 4 */
                -webkit-transition: visibility 1s; /* Safari and Chrome */
                -o-transition: visibility 1s;
                transition: visibility 1s; /* Opera */
            }
        }



        .data-col-prediction .val span {
            visibility: visible;
        }
    }
}



.chart .val {
    @media (max-width: @screen-xs-max) {
        font-size: 13px;
    }
}

