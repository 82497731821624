﻿
// Font path (Proxima nova)
@set-font-path:          "../Content/fonts/";

@brand-primary:         #ff9d00; 

// Navbar links
@navbar-default-link-color:                #231f20;
@navbar-default-link-hover-color:          #fff;
@navbar-default-link-hover-bg:             #ff9d00;
@navbar-default-link-active-color:         #fff;
@navbar-default-link-active-bg:            #231f20;

// Basics of a navbar
@navbar-height:                    26px;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 1));

@navbar-default-color:             #231f20;
@navbar-default-bg:                #fff;

//=== Shared nav styles
@nav-link-padding:                          10px 8px;

@text-color:            #231f20;

//== Typography
@font-family-sans-serif:  "Proxima N W02 Reg", Arial, sans-serif;

@font-size-base:          15px;

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

@headings-font-family:    "Proxima N W02 Xbold", Arial, sans-serif;



// Number of columns in the grid.
@grid-columns:              15;


//== Forms
//
//##

//** `<input>` background color
@input-bg:                       #fff;
//** `<input disabled>` background color
@input-bg-disabled:              @gray-lighter;

//** Text color for `<input>`s
@input-color:                    #333;
//** `<input>` border color
@input-border:                   #333;

// TODO: Rename `@input-border-radius` to `@input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
@input-border-radius:            none;

//** Border color for inputs on focus
@input-border-focus:             #ff9d00;

//** Placeholder text color
@input-color-placeholder:        #999;



//== Modals
//
//##

//** Padding applied to the modal body
@modal-inner-padding:         40px;

//** Background color of modal content area
@modal-content-bg:                             #f6f6f6;
//** Modal content border color



//** Modal backdrop background color
@modal-backdrop-bg:           #f6f6f6;
//** Modal backdrop opacity
@modal-backdrop-opacity:      .0;
//** Modal header border color
@modal-header-border-color:   #e5e5e5;
//** Modal footer border color



// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-md-min;

// Navbar toggle
@navbar-default-toggle-hover-bg:           transparent;
@navbar-default-toggle-icon-bar-bg:        #333;
@navbar-default-toggle-border-color:       transparent;

