//
// Fonts
// --------------------------------------------------

@font-face{
font-family:"Proxima N W02 Light";
src:url("@{set-font-path}8d5c60e0-a2b9-449c-a43f-d96f77864f21.eot?#iefix");
src:url("@{set-font-path}8d5c60e0-a2b9-449c-a43f-d96f77864f21.eot?#iefix") format("eot"),
		url("@{set-font-path}7e36b526-9c4e-4d9e-b125-882b559cb226.woff2") format("woff2"),
		url("@{set-font-path}978c0880-c171-4791-b0cd-64854b401b0f.woff") format("woff"),
		url("@{set-font-path}8eee92a1-1e33-4c77-b7ce-85da60d3809d.ttf") format("truetype"),
		url("@{set-font-path}cdea7c46-5628-43f5-9031-f810d4c4598c.svg#cdea7c46-5628-43f5-9031-f810d4c4598c") format("svg");
}

@font-face{
font-family:"Proxima N W02 Reg";
src:url("@{set-font-path}981c9065-bc6e-45d0-a53d-3586c9477bc1.eot?#iefix");
src:url("@{set-font-path}981c9065-bc6e-45d0-a53d-3586c9477bc1.eot?#iefix") format("eot"),
		url("@{set-font-path}2f984d7d-ee4e-4936-8288-fe7f8637788f.woff2") format("woff2"),
		url("@{set-font-path}74df122e-b294-4b16-a1fd-308654b1c664.woff") format("woff"),
		url("@{set-font-path}7389e916-8cc8-4f82-9de6-4174b4ea5dd5.ttf") format("truetype"),
		url("@{set-font-path}67059dfa-2992-46b3-a928-def96c0f85fe.svg#67059dfa-2992-46b3-a928-def96c0f85fe") format("svg");
}

@font-face{
font-family:"Proxima N W02 Bold";
src:url("@{set-font-path}b9234758-e038-4622-b531-1e89eef11dc8.eot?#iefix");
src:url("@{set-font-path}b9234758-e038-4622-b531-1e89eef11dc8.eot?#iefix") format("eot"),
		url("@{set-font-path}301b29be-a6b5-467a-ba10-926b40436a87.woff2") format("woff2"),
		url("@{set-font-path}a01242ac-07ee-44eb-97b4-3f6df729e51a.woff") format("woff"),
		url("@{set-font-path}713e8042-cdd9-4f87-b79b-59bb8e43daca.ttf") format("truetype"),
		url("@{set-font-path}b39f961b-4c68-4114-8a71-76bfe004cf14.svg#b39f961b-4c68-4114-8a71-76bfe004cf14") format("svg");
}

@font-face{
font-family:"Proxima N W02 Xbold"; 
src:url("@{set-font-path}98efc59a-892b-4055-a50c-3a62f5f46e88.eot?#iefix");
src:url("@{set-font-path}98efc59a-892b-4055-a50c-3a62f5f46e88.eot?#iefix") format("eot"),
		url("@{set-font-path}fc89076c-e531-4a6c-a0a0-cb26387f4260.woff2") format("woff2"),
		url("@{set-font-path}0333c78e-0245-4dd1-91f0-95e76486e4fe.woff") format("woff"),
		url("@{set-font-path}075af3d9-ba60-4f41-8fa1-fc89feed423d.ttf") format("truetype"),
		url("@{set-font-path}7eec8f01-73b9-4a88-a52d-6e36ef34ccec.svg#7eec8f01-73b9-4a88-a52d-6e36ef34ccec") format("svg");
}