﻿body {
  padding-top: 0;
}

a { 
    outline: none;
    &:hover { outline: none; } 
    &:focus { outline: none; } 
}


// Homepage
.hp-accordeon {
  .make-md-column(15);
  padding-left: 0;
  padding-right: 0;
  /*margin-top: 75px;*/
  img {
    .img-responsive();
  }
}

// Obyčejná stránka
.title-container {
  background-color: #eeeeee;

  h1 {
    text-align: center;
  }
}

.content {
  .make-md-column(15);
}

.navbar-header {
  padding-left: 30px;

  .navbar-brand {
    width: 180px;
    height: 45px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 100%;
    text-indent: -9999px;
    background-image: url("../Content/img/logo.png");
    margin-top: 10px;
    padding-left: 30px;

    @media (max-width: 470px) {
      background-image: url("../Content/img/logo-small.png");
      width: 180px;
      height: 45px;
    }
  }
}

.navbar-text {
  margin-top: 26px;
}

.highlight {
  font-family: "Proxima N W02 Bold", Arial, sans-serif;
}


.advantages-wrapper {
  margin-top: 50px;
  margin-bottom: 40px;

  .advantage {
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}


// Default navbar
.navbar-default {
  height: 76px;
  margin-bottom: 0;

  .navbar-text {
    color: @navbar-default-color;
  }

  .navbar-nav {

    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      margin-left: 15px;

      &.navbar-right {
        display: none;
      }
    }

    margin-left: 30px;
    margin-top: 24px;





    > li > a {
      color: @navbar-default-link-color;
      font-family: "Proxima N W02 Bold", Arial, sans-serif;
      text-transform: uppercase;
      font-size: 15px;
      margin-right: 10px;

      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        font-size: 12px;
        margin-right: 2px;
      }

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-color;
        background-color: @navbar-default-link-hover-bg;
      }
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
  }

  .navbar-nav.navbar-right {
      @media (max-width: @screen-xs-max) {  display: none; }
    > li > a {
      color: @navbar-default-link-color;
      font-size: 18px;

      &:hover,
      &:focus {
        color: @navbar-default-link-hover-bg;
        background-color: transparent;
      }
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-hover-bg;
        background-color: transparent;
      }
    }
  }
}


.navbar-fixed-top,
.navbar-static-top {
  border-width: 0 0 0px;
  .box-shadow(0 1px 18px rgba(0,0,0,.1));
}

.navbar-toggle {
  margin-top: 22px;
}



.subpage-vizual {
  height: 259px;
  margin-bottom: 40px;
  display: block;

  .container {
    display: table;
    height: 100%;

    .wrapper {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      color: #fff;

      h1 {
        font-size: 60px;
        margin-top: 14px;
      }
    }
  }
}

.container.main {
  .box {
    .make-xs-column(11);
    .make-xs-column-offset(2);

    &.aside-first {
      .make-sm-column(5);

      .btn-primary {
        float: right;
      }

      label {
        display: none;
      }
    }

    &.box-main {
      .make-sm-column(5);
      .make-xs-column-offset(0);
    }

    h2 {
      font-size: 24px;
    }

    ul {
      padding-left: 16px;
    }

    .lead {
      font-family: "Proxima N W02 Light", Arial, sans-serif;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.4;
    }

    a {
      font-family: "Proxima N W02 Xbold", Arial, sans-serif;

      &:hover,
      &:focus {
        text-decoration: none;
        color: #231f20;
      }
    }

    .news h2 {
      margin-bottom: 5px;
    }
  }
}


.btn-primary {
  border: none;
  text-transform: none;
  .border-right-radius(0);
  .border-left-radius(0);
  background-color: #231f20;


  &:hover,
  &:focus {
    background-color: #000;
    .box-shadow(none);
  }
}


//
// O nás
// --------------------------------------------------

.member {


  &:hover {
    h2 {
      color: #ff9d00; 
    }

    cursor: pointer;
  }

  .make-sm-column(5);
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 34px;
  padding-right: 34px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  img {
    .border-top-radius(240px);
    .border-bottom-radius(240px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}


//
// Chci investovat
// --------------------------------------------------

.invest {
  .make-sm-column(5);
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 34px;
  padding-right: 34px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  img {
    .border-top-radius(0px);
    .border-bottom-radius(0px);
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  
}

.modal-content {
    cursor: default;
    border-radius: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    label {
        font-size: 20px;
        margin-bottom: 14px;
        font-family: "Proxima N W02 Xbold", Arial, sans-serif;
        font-weight: 400;
    }


    input {
        text-align: center;
    }



    h2 {
        color: #000;
        margin-bottom: 0px;
    }

    p.funkce {
        font-family: "Proxima N W02 Bold", Arial, sans-serif;
        font-size: 19px;
    }

    img {
        .border-top-radius(840px);
        .border-bottom-radius(840px);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}


.navbar-default .navbar-collapse,
.navbar-default .navbar-form {


    @media (max-width: @screen-xs-max) {
        border-color: #e4e4e4;
        margin-top: 21px;
        background-color: #fff;
         .box-shadow(0 1px 3px rgba(0,0,0,.1));
    }
}

.navbar-default .navbar-nav {

    @media (max-width: @screen-xs-max) {
        margin-left: 0px;
        margin-top: 18px;
    }
}

.navbar-nav {
    @media (max-width: @screen-xs-max) {
        margin: 0px 0px 14px 0;
    }
}

.navbar-default .navbar-nav > li > a {
    @media (max-width: @screen-xs-max) {
        margin-right: 0px;
        margin-bottom: 7px;
    }
}


// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {


  padding: 9px 10px;
  
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid red;
  border-radius: 0;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    display: block;
    width: 26px;
    height: 3px;
    border-radius: 0;
  }
  .icon-bar + .icon-bar {
    margin-top: 3px;
  }

  @media (min-width: @grid-float-breakpoint) {
    display: none;
  }
}

.kontakt .btn-primary {
    padding: 9px 15px;
}

button, a , button:active, button:focus, a:active, a:focus {
    outline: 0 !important;
    outline-offset: 0 !important;
    outline-style: none !important;
}